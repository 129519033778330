<template>
  <div class="zt-component-main">
    <div class="component-label" style="display: inline-block;">
      <slot></slot>
      {{ widget.componentName }}：
    </div>
    <!-- <div style="display: inline-block; word-wrap: break-word;word-break: normal;">{{ computedWidgetCustomContent }}</div>-->
    <div style="display: inline-block; word-wrap: break-word;word-break: normal;">{{ Number(pastEvents) === 0 ? '否' : pastRaceName }}</div>
  </div>
</template>

<script type="text/ecmascript-6">

import ComponentMixin from './component-mixin'
import {isEmpty}      from 'lodash'

export default {
  extends   : ComponentMixin,
  mixins    : [],
  components: {},
  name      : 'ZtPhone',
  props     : {
    className: { type: String, default: '' },
    widget   : { type: Object, required: true },
  },
  data() {
    return {
      pastRaceName: '',
      pastEvents  : '',
    }
  },
  watch   : {
    // someObject: { handler: function (val, oldVal) { /* ... */ }, deep: true, immediate: true },
  },
  computed: {},
  methods : {
    //
  },
  created() {
    // document.documentElement.scrollTop = 0
  },
  destroyed() {},
  mounted() {
    if (isEmpty(this.widget)) {throw new Error('widget is empty')}
    if (Reflect.has(this.widget, 'answer') && this.widget) {
      let parse         = JSON.parse(this.widget.answer)
      this.pastRaceName = parse.pastRaceName
      this.pastEvents   = parse.pastEvents
    }
  },
}
</script>

<style scoped lang="scss" rel="stylesheet/scss">
@import "component.scss";
</style>
